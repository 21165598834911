//
// Grid system
// --------------------------------------------------

// Set the container width, and override it for fixed navbars in media queries
.container {
  .container-fixed();

  @media (min-width: @screen-sm) {
    width: @container-sm;
  }
  @media (min-width: @screen-md) {
    width: @container-md;
  }
  @media (min-width: @screen-lg-min) {
    width: @container-lg;
  }
}

// mobile first defaults
.row {
  .make-row();
}

// Common styles for small and large grid columns
.make-grid-columns();


// Extra small grid
//
// Columns, offsets, pushes, and pulls for extra small devices like
// smartphones.

.make-grid-columns-float(xs);
.make-grid(@grid-columns, xs, width);
.make-grid(@grid-columns, xs, pull);
.make-grid(@grid-columns, xs, push);
.make-grid(@grid-columns, xs, offset);


// Small grid
//
// Columns, offsets, pushes, and pulls for the small device range, from phones
// to tablets.

@media (min-width: @screen-sm-min) {
  .make-grid-columns-float(sm);
  .make-grid(@grid-columns, sm, width);
  .make-grid(@grid-columns, sm, pull);
  .make-grid(@grid-columns, sm, push);
  .make-grid(@grid-columns, sm, offset);
}


// Medium grid
//
// Columns, offsets, pushes, and pulls for the desktop device range.

@media (min-width: @screen-md-min) {
  .make-grid-columns-float(md);
  .make-grid(@grid-columns, md, width);
  .make-grid(@grid-columns, md, pull);
  .make-grid(@grid-columns, md, push);
  .make-grid(@grid-columns, md, offset);
}


// Large grid
//
// Columns, offsets, pushes, and pulls for the large desktop device range.

@media (min-width: @screen-lg-min) {
  .make-grid-columns-float(lg);
  .make-grid(@grid-columns, lg, width);
  .make-grid(@grid-columns, lg, pull);
  .make-grid(@grid-columns, lg, push);
  .make-grid(@grid-columns, lg, offset);
}

